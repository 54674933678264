import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import emailjs from "emailjs-com";
import { Row, Col } from "reactstrap";
import toast from "react-hot-toast";

import { countriesData } from "../Common/data/countriesData";

import "./Contact.css";

// Image Imports

import location_reach from "../../../images/icons/contactus/location_reach.svg";
import WhatsApp from "../../../images/icons/contactus/whatsapp.svg";
import Query from "../../../images/icons/contactus/query.svg";
import Job from "../../../images/icons/contactus/job.svg";
import india_flag from "../../../images/icons/contactus/india_flag.svg";
import right_arrow_white from "../../../images/right_arrow_white.svg";
import Sending from "../../../images/sending.gif";

// End of Image Imports

import useCustomForm from "../../../helpers/form/CustomForm";

function Contact() {
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState(countriesData);
  const [countriesList, setCountriesList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const initialValues = {
    full_name: "",
    phone_number: "",
    email: "",
    country_code: "",
    country: "",
    subject: "",
    message: "",
  };

  const {
    values: inputs,
    handleChange,
    setValues,
  } = useCustomForm({
    initialValues,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Contact us • Devship";

    const countriesList = countries.map((country) => {
      return {
        label: country.name,
        value: country.name,
        code: country.code,
      };
    });
    setCountriesList(countriesList);
  }, []);

  const {
    full_name,
    phone_number,
    email,
    country_code,
    country = selectedCountry,
    subject,
    message,
  } = inputs;

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (full_name === "") {
      return toast.error("Please let us know your name");
    }
    if (email === "") {
      return toast.error("Please provide us your Email Address", {
        id: "email",
      });
    } else if (!validateEmail(email)) {
      return toast.error("Seems like an invalid email", {
        id: "email",
      });
    }
    if (phone_number === "") {
      return toast.error("Please provide us your Contact Number");
    }
    if (subject === "") {
      return toast.error("Please enter your subject");
    }
    if (message === "") {
      return toast.error("Please type in your message");
    }

    // request({
    //   url: "/contactus",
    //   method: "POST",
    //   data: {
    //     full_name: full_name,
    //     phone_number: phone_number,
    //     email: email,
    //     subject: subject,
    //     message: message,
    //   },
    // }).then((res) => {
    setLoading(true);
    emailjs
      .sendForm(
        "service_ss2d4cn",
        "template_k2mqu4q",
        form.current,
        "m7ZuMER00eb3yeouD"
      )
      .then(
        () => {
          setLoading(false);
          toast.success("Submitted Successfully!");
        },
        (error) => {
          toast.error("Sorry, try again!", error);
        }
      );
    setValues(initialValues);
    setSelectedCountry("");
  };

  return (
    <>
      <section className="contact-us">
        <div className="container">
          <div className="contact-content">
            <div className="contact-us-header">
              <div className="container">
                <h2>Hi! We Love to Hear From You</h2>
                <h3>
                  Want to learn more about how we can help with your next big
                  project? <br /> Reach out to us!
                </h3>
              </div>
            </div>
            <Row>
              <Col sm="12" md="12" lg="6">
                <div className="contact-form">
                  <h5>Get in Touch</h5>
                  <h6>Fill up the contact form</h6>
                  <form
                    ref={form}
                    method="POST"
                    name="contact_responses"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-box">
                      <label htmlFor="full_name">Full Name</label>
                      <input
                        id="full_name"
                        type="text"
                        name="full_name"
                        className="form-control"
                        value={full_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-box">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                        value={email}
                        onChange={handleChange}
                      />
                    </div>
                    <Row>
                      <Col sm="12" lg="5" md="5">
                        <div className="form-box">
                          <label htmlFor="email">Country</label>
                          <Select
                            isClearable={false}
                            isSearchable={true}
                            isOptionSelected={true}
                            cacheOptions
                            classNamePrefix="select_box"
                            placeholder="Select Country"
                            options={countriesList}
                            value={selectedCountry}
                            name="country"
                            onChange={(selectedOption) =>
                              setSelectedCountry(selectedOption)
                            }
                          />
                        </div>
                      </Col>
                      <Col sm="12" lg="7" md="7">
                        <div className="form-box country">
                          <label htmlFor="email">Contact Number</label>
                          <div className="d-flex align-items-center gap-2">
                            {selectedCountry.code && (
                              <input
                                type="text"
                                className="country-code-input"
                                value={selectedCountry.code}
                                name="country_code"
                                id="country_code"
                                readOnly
                                onChange={handleChange}
                              />
                            )}
                            <input
                              type="tel"
                              name="phone_number"
                              id="phone_number"
                              className="form-control"
                              value={phone_number}
                              onChange={handleChange}
                              maxlength="15"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="form-box">
                      <label htmlFor="subject">Subject</label>
                      <input
                        id="subject"
                        type="text"
                        name="subject"
                        className="form-control"
                        value={subject}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-box">
                      <label htmlFor="msg_textarea">Message</label>
                      <textarea
                        name="message"
                        id="msg_textarea"
                        className="form-control"
                        value={message}
                        onChange={handleChange}
                        rows="4"
                      ></textarea>
                    </div>
                    <div className="d-flex align-items-center justify-content-end mt-2">
                      {loading ? (
                        <div className="sending-msg">
                          <img src={Sending} alt="Sending" />
                          <h4>Submitting your message ...</h4>
                        </div>
                      ) : (
                        <button
                          className="common-btn"
                          name="submit"
                          type="submit"
                        >
                          Submit
                          <div className="common-btn-img">
                            <img src={right_arrow_white} alt="Right" />
                          </div>
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </Col>
              <Col sm="12" md="12" lg="6">
                <div className="contact-addr-wrap">
                  <div className="conadr-text">
                    <h5>
                      <img src={location_reach} alt="Location" />
                      Reach Us
                    </h5>
                    <h6>
                      <img src={india_flag} alt="India" />
                      India
                    </h6>
                    <p className="addr-txt">
                      Awfis, Ground Floor, Spero Primus Building, <br /> Door
                      No. SP – 7A, SIDCO Industrial Estate, <br /> Guindy,
                      Chennai - 600 032, Tamil Nadu, India
                    </p>
                  </div>
                  <div className="map-wrap">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.330259583476!2d80.19911337489374!3d13.0146284873047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267a73ea8f7c9%3A0xd7cb60c8a4a163cd!2sAwfis%20Spero%20Primus!5e0!3m2!1sen!2sin!4v1723909948446!5m2!1sen!2sin"
                      width={"100%"}
                      height="450"
                      style={{ border: 0 }}
                      allowfullscreen=""
                      loading="lazy"
                      title="Devship"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="contact-info-wrap">
            <div className="contact-info">
              <Row>
                <Col sm="12" md="4" lg="4">
                  <div className="con-item">
                    <img
                      src={WhatsApp}
                      className="lazy"
                      data-mdb-lazy-src
                      alt="WhatsApp"
                    />
                    <div className="con-det">
                      <h5>Reach us in WhatsApp</h5>
                      <h6>
                        <a
                          href="https://wa.me/+918148984627"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          +91 81489 84627
                        </a>
                      </h6>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="4" lg="4">
                  <div className="con-item" data-no-border>
                    <img
                      src={Job}
                      className="lazy"
                      data-mdb-lazy-src
                      alt="Job"
                    />
                    <div className="con-det">
                      <h5>For any job related queries</h5>
                      <h6>
                        <a
                          href="mailto:hr@devship.in"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          hr@devship.in
                        </a>
                      </h6>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="4" lg="4">
                  <div className="con-item" data-line>
                    <img
                      src={Query}
                      className="lazy"
                      data-mdb-lazy-src
                      alt="Query"
                    />
                    <div className="con-det">
                      <h5>For any queries</h5>
                      <h6>
                        <a
                          href="mailto:hello@devship.in"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          hello@devship.in
                        </a>
                      </h6>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
