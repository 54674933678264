import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

// Image Imports

import ServicesImg from "../../../images/services.png";
import serv_assistance_img from "../../../images/serv_assistance_img.svg";
import right_arrow_white from "../../../images/right_arrow_white.svg";

// End of Image Imports

import Assistance from "../Common/Assistance";

import "./Services.css";

// Services

const frontEndServices = [
  {
    id: "01",
    name: "Web & App Development",
    desc: [
      {
        title: "Cutting-Edge Web Solutions",
        desc: "We adapt to evolving web technologies to meet the demand for fast, secure, and interactive websites. Our complete front-end and back-end development services are based on the latest technologies and industry trends, tailored to your business needs.",
      },
      {
        title: "Innovative Mobile Solutions",
        desc: "We use the latest technologies to develop high-quality mobile apps for your business. Our solutions help you manage and run your business efficiently, providing access to critical information and increasing productivity while reducing costs.",
      },
    ],
  },
];

const backEndServices = [
  {
    id: "02",
    name: "Cloud & Server",
    desc: [
      {
        title: "Streamline and Secure Your Operations",
        desc: "DevOps is the future of project management, integrating Development, Operations, and Security divisions to deliver highly secure applications and services at high velocity. We can establish an efficient and secure project management environment, ensuring smooth and hassle-free project delivery while reducing costs.",
      },
      {
        title: "Efficient Cloud Solutions",
        desc: "Have a ready product but facing deployment issues? We provide managed server services, ensuring seamless setup, management, and troubleshooting. Your project deserves the best cloud infrastructure, and we deliver top-tier service to support your web presence.",
      },
    ],
  },
];

const designServices = [
  {
    id: "03",
    name: "Web & Graphic Design",
    desc: [
      {
        title: "Creative Design Solutions",
        desc: "Our experienced graphic design team brings your ideas to life with the right colours, graphics, and layouts. We create appealing designs that attract your target audience, ensuring your dream designs are just a step away.",
      },
    ],
  },
];

const marketingServices = [
  {
    id: "04",
    name: "Technical Consulting",
    desc: [
      {
        title: "Optimize Your Digital Presence",
        desc: "Your digital presence is crucial for generating leads and building a robust customer base. We use advanced tools and techniques to analyze, consult, and solve your business challenges. Our services encompass website management, lead generation, social media, SEO, and more to strengthen your digital footprint.",
      },
    ],
  },
  {
    id: "05",
    name: "Branding & Digital Marketing",
    desc: [
      {
        title: "Build a Powerful Brand",
        desc: "Brands like Apple, Google, and Facebook thrive on effective branding. We use the right tools and strategies to build your brand, create engaging content, and generate strong leads. Our approach ensures a strong customer base and lasting brand recognition.",
      },
    ],
  },
  {
    id: "06",
    name: "Content Writing & SEO",
    desc: [
      {
        title: "Content is King",
        desc: "Quality content is timeless. We craft relevant and compelling content to engage visitors and convert them into potential clients. Our SEO services enhance your online visibility, driving traffic and improving search engine rankings.",
      },
    ],
  },
];

function Services() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "What we offer • Devship";
  }, []);

  // Tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <section className="services-section">
      <div className="container-fluid">
        <div className="services-header">
          <Row>
            <Col sm="12" lg="5" md="12" className="d-flex align-items-center">
              <div className="serv-head-text">
                <h2>Discover Our Expertise</h2>
                <h6>
                  Explore a wide array of services tailored to your dream
                  projects, ensuring exceptional quality and competitive
                  pricing.
                </h6>
                <Link to="/contact">
                  <button className="common-btn">
                    Get in Touch
                    <div className="common-btn-img">
                      <img src={right_arrow_white} alt="Right" />
                    </div>
                  </button>
                </Link>
                <h6>
                  We offer an extensive range of services to meet your business
                  needs with flexible and affordable pricing. As your
                  comprehensive solution provider, we assist with everything
                  from branding your project to reaching your target audience
                  swiftly. Our services encompass detailed project management to
                  extensive marketing strategies to firmly establish your brand.
                </h6>
              </div>
            </Col>
            <Col sm="12" lg="7" md="12" className="p-0">
              <img
                src={ServicesImg}
                className="lazy services-img"
                data-mdb-lazy-src
                alt="Services"
              />
            </Col>
          </Row>
        </div>
      </div>
      <div className="services">
        {/* Services */}
        <div className="container">
          <Row>
            <Col sm="12" lg="5" md="12">
              <Nav id="servicesTab" tabs vertical>
                <NavItem>
                  <NavLink
                    className={`first-child ${
                      activeTab === "1" ? "active" : ""
                    }`}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Front End
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${activeTab === "2" ? "active" : ""}`}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Back End
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${activeTab === "3" ? "active" : ""}`}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    Design
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`last-child ${
                      activeTab === "4" ? "active" : ""
                    }`}
                    onClick={() => {
                      toggle("4");
                    }}
                  >
                    Marketing
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col sm="12" lg="7" md="12" className="d-flex align-items-center">
              <div className="services-item-wrap">
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    {frontEndServices.map((item, i) => {
                      return (
                        <div className="services-item" key={i}>
                          <span>{item.id}</span>
                          <h4>{item.name}</h4>
                          {item.desc.map((desc) => {
                            return (
                              <div className="services-desc">
                                <h5>{desc.title}</h5>
                                <h6>{desc.desc}</h6>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </TabPane>
                  <TabPane tabId="2">
                    {backEndServices.map((item) => {
                      return (
                        <div className="services-item">
                          <span>{item.id}</span>
                          <h4>{item.name}</h4>
                          {item.desc.map((desc) => {
                            return (
                              <div className="services-desc">
                                <h5>{desc.title}</h5>
                                <h6>{desc.desc}</h6>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </TabPane>
                  <TabPane tabId="3">
                    {designServices.map((item) => {
                      return (
                        <div className="services-item">
                          <span>{item.id}</span>
                          <h4>{item.name}</h4>
                          {item.desc.map((desc) => {
                            return (
                              <div className="services-desc">
                                <h5>{desc.title}</h5>
                                <h6>{desc.desc}</h6>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </TabPane>
                  <TabPane tabId="4">
                    {marketingServices.map((item) => {
                      return (
                        <div className="services-item">
                          <span>{item.id}</span>
                          <h4>{item.name}</h4>
                          {item.desc.map((desc) => {
                            return (
                              <div className="services-desc">
                                <h5>{desc.title}</h5>
                                <h6>{desc.desc}</h6>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
        {/* End of Services */}
      </div>
      <Assistance
        title="Need Assistance?"
        desc="Let's meet over a coffee to discuss your next big dream project. We're here to help you every step of the way."
        button="Reach Us"
        link="/contact"
        imgURL={serv_assistance_img}
        background={false}
      />
    </section>
  );
}

export default Services;
