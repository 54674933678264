import React, { useState, useEffect } from "react";

import Logo from "../../../images/logo_icon.svg";

function Preloader() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2500);

    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <div className={`preloader ${loading ? "" : "pre-hide"}`}>
      <div className="preloader-content">
        <img
          src={Logo}
          className="lazy"
          data-mdb-lazy-src
          alt="Logo"
          width={70}
        />
        <div className="pre-line">
          <div className="pre-fill-line"></div>
        </div>
        {/* <img
          src={PreloaderImg}
          className="lazy"
          data-mdb-lazy-src
          alt="Preloader"
          id="pre"
        /> */}
        {/* <h6>We are getting things ready for you...</h6> */}
      </div>
    </div>
  );
}

export default Preloader;
