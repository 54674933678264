import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

// Image Imports

import right_arrow_white from "../../../images/right_arrow_white.svg";

// End of Image Imports

function Assistance(props) {
  return (
    <div
      className="common-cta-down"
      style={{ background: props.background === false ? "none" : "" }}
    >
      <div className="cta-down-text">
        <h2>{props.title}</h2>
        <h6>{props.desc}</h6>
        <Link to={props.link} className="d-inline-block mt-5">
          <button className="common-btn">
            {props.button}
            <div className="common-btn-img">
              <img src={right_arrow_white} alt="Right" />
            </div>
          </button>
        </Link>
      </div>
      <img
        loading="lazy"
        src={props.imgURL}
        className="lazy asst-img"
        data-mdb-lazy-src
        alt="Assistance"
      />
    </div>
  );
}

export default Assistance;
