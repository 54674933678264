import React, { useState, useEffect } from "react";
import "./Common.css";

// Image Imports

import Gotop from "../../../images/Gotop.svg";

// End of Image Imports

function GoTop() {
  const [pageScrolled, setPageScrolled] = useState(false);
  const handleArrow = () => {
    const position = window.scrollY;
    if (position + window.innerHeight > 1250) {
      setPageScrolled(true);
    } else {
      setPageScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleArrow, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleArrow);
    };
  }, []);
  const goTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={`go_top_wrap ${pageScrolled ? "show" : ""}`}>
      <img
        loading="lazy"
        className="go_top lazy"
        src={Gotop}
        data-mdb-lazy-src
        alt="Go to Top"
        onClick={goTop}
      />
    </div>
  );
}

export default GoTop;
