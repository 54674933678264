import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "./Team.css";

// Image Imports

import ceo_pic from "../../../images/team/ceo/ceo_pic.png";
import ceo_pro_pic from "../../../images/team/ceo/ceo_pro_pic.png";
import usp_img from "../../../images/team/ceo/usp_img.svg";
import csr_img from "../../../images/team/ceo/csr_img.svg";
import join_img from "../../../images/team/ceo/join_img.png";
import cta_connect from "../../../images/team/ceo/cta_connect.png";
import linkedinIcon from "../../../images/team/ceo/linkedinIcon.svg";
import ceo_visionary from "../../../images/team/ceo/ceo_visionary.svg";
import ceo_visionary_selected from "../../../images/team/ceo/ceo_visionary_selected.svg";
import ceo_track from "../../../images/team/ceo/ceo_track.svg";
import ceo_track_selected from "../../../images/team/ceo/ceo_track_selected.svg";
import ceo_excel from "../../../images/team/ceo/ceo_excel.svg";
import ceo_excel_selected from "../../../images/team/ceo/ceo_excel_selected.svg";
import ceo_future from "../../../images/team/ceo/ceo_future.svg";
import ceo_future_selected from "../../../images/team/ceo/ceo_future_selected.svg";

// End of Image Imports

function MeetOurCEO() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "Meet our CEO | Devship - Web and Mobile App Design, Development, Management, Deployment, and Targeted Marketing";
  }, []);

  // Accordion
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  const options = {
    loop: false,
    margin: 10,
    items: 1,
    nav: false,
    autoplay: false,
    dots: true,
  };

  return (
    <section className="team-ceo-section">
      <div className="container">
        {/* CEO Intro */}
        <div className="team-ceo-intro">
          <Row>
            <Col sm="12" lg="4" md="5">
              <div className="team-ceo-img-prof">
                <img src={ceo_pro_pic} alt="CEO" />
              </div>
            </Col>
            <Col sm="12" lg="8" md="7" className="d-flex align-items-center">
              <div className="team-ceo-text-wrap">
                <h4>
                  Meet Our <span>CEO</span>
                </h4>
                <h3>
                  Shunmugam <span>Harikrishnan</span>
                </h3>
                <h6>Visionary Leadership for Devship Pvt Ltd</h6>
                <p>
                  Shunmugam Harikrishnan, our esteemed CEO, is a dynamic and
                  visionary leader with a proven track record in the Software
                  and Information Technology industry. With over six years of
                  experience in the software and IT field, Harikrishnan brings a
                  wealth of knowledge and an unwavering commitment to Devship
                  Pvt Ltd.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        {/* USP */}
        <div className="team-ceo-usp">
          <Row>
            <Col sm="12" lg="8" md="8">
              <div className="team-ceo-usp-points">
                <Accordion open={open} toggle={toggle}>
                  <AccordionItem>
                    <AccordionHeader targetId="1">
                      <div className="tcusp-img">
                        <img
                          src={
                            open === "1"
                              ? ceo_visionary_selected
                              : ceo_visionary
                          }
                          alt="Visionary"
                        />
                      </div>
                      A Visionary Leader
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      <p>
                        Shunmugam is committed to Devship's mission of
                        developing the dream projects of our clients into
                        reality by providing solutions to complex business
                        problems with appropriate high-end technology of
                        superior quality at an affordable price. Under his
                        visionary leadership, we are poised for unprecedented
                        growth and success. Shunmugam's strategic vision,
                        passion, and innovative thinking are instrumental in
                        driving our company forward.
                      </p>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId="2">
                      <div className="tcusp-img">
                        <img
                          src={open === "2" ? ceo_track_selected : ceo_track}
                          alt="Track"
                        />
                      </div>
                      Proven Track Record
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                      <p>
                        Shunmugam's career is marked by his exceptional
                        achievements in the IT sector. He has held leadership
                        positions in many organisations and has a strong history
                        of guiding teams in companies to reach new heights.
                        Harikrishnan has consistently delivered remarkable
                        results throughout his career and leadership roles.
                      </p>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId="3">
                      <div className="tcusp-img">
                        <img
                          src={open === "3" ? ceo_excel_selected : ceo_excel}
                          alt="Excel"
                        />
                      </div>
                      Commitment to Excellence
                    </AccordionHeader>
                    <AccordionBody accordionId="3">
                      <p>
                        Shunmugam is dedicated to upholding Devship's commitment
                        to excellence, quality, and customer satisfaction. He
                        believes in fostering a culture of continuous
                        improvement, where every team member is encouraged to
                        innovate and strive for excellence.
                      </p>
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionHeader targetId="4">
                      <div className="tcusp-img">
                        <img
                          src={open === "4" ? ceo_future_selected : ceo_future}
                          alt="Future"
                        />
                      </div>
                      A Vision for the Future
                    </AccordionHeader>
                    <AccordionBody accordionId="4">
                      <p>
                        Under Shunmugam Harikrishnan's leadership, we are poised
                        to achieve tremendous success and expand our horizons.
                        He envisions building ourselves to become the most
                        reliable digital and technological service provider to
                        bring our customer’s desired plans into models, offering
                        a wide range of high-point facilities, mainly supporting
                        the UK healthcare sector.
                      </p>
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </div>
            </Col>
            <Col sm="12" lg="4" md="4">
              <div className="team-ceo-usp-img">
                <img src={usp_img} alt="USP" />
              </div>
            </Col>
          </Row>
        </div>
        {/* Social Service */}
        <div className="team-ceo-social-service">
          <Row>
            <Col sm="12" lg="6" md="6">
              <h4>
                Philanthropic Interests and <span>Social Service</span>
              </h4>
              <p>
                Shunmugam is actively involved in various social service and
                philanthropic initiatives, contributing time, resources, and
                expertise to causes that align with our values. Some of the
                areas he is mainly dedicated to include.
              </p>
              <p>
                Shunmugam's dedication to these causes reflects the values and
                principles that drive our company. We believe in using our
                success to create a better world, and Shunmugam Harikrishnan
                leads by example.
              </p>
            </Col>
            <Col sm="12" lg="6" md="6">
              <div className="team-ceo-ss-slider-wrap">
                <OwlCarousel className="owl-theme" {...options}>
                  <div className="item">
                    <h5>Rotary &amp; Rotaract</h5>
                    <span>01</span>
                    <p>
                      Shunmugam is an active (member) Rotarian of the Rotary
                      Club of Great Britain and Northern Ireland and an active
                      (member) Rotaractor of the Rotaract Club of Akash,
                      Chennai, India, significantly supporting the causes and
                      missions of the Rotary International, the world’s largest
                      social service organisation.
                    </p>
                  </div>
                  <div className="item">
                    <h5>Rotary &amp; Rotaract</h5>
                    <span>02</span>
                    <p>
                      The main focus areas of service for Shunmugam are mental
                      health and supporting education for children. He is a
                      rigid volunteer supporting the charity “Udhavum Ullangal”
                      (Helping Hearts) in Tirunelveli, India. He has also
                      volunteered and organised mental health camps and
                      campaigns with the Rotaract Club to provide better mental
                      health support to teenagers.
                    </p>
                  </div>
                </OwlCarousel>
              </div>
            </Col>
          </Row>
        </div>
        {/* CSR */}
        <div className="team-ceo-csr">
          <Row>
            <Col sm="12" lg="6" md="6">
              <div className="tc-csr-text">
                <h4>
                  Corporate <span>Social Responsibility</span>
                </h4>
                <p>
                  At Devship, we are committed to corporate social
                  responsibility (CSR) and strive to make a meaningful
                  difference in our communities. Shunmugam Harikrishnan sets the
                  tone for our CSR initiatives by actively participating in and
                  promoting philanthropic efforts that benefit society.
                </p>
              </div>
            </Col>
            <Col
              sm="12"
              lg="6"
              md="6"
              className="d-flex align-items-center justify-content-end"
            >
              <div className="team-ceo-csr-img">
                <img src={csr_img} alt="CSR" />
              </div>
            </Col>
          </Row>
        </div>
        {/* Last CTA Section */}
        <div className="team-ceo-lastcta">
          <Row>
            <Col sm="12" lg="6" md="6">
              <div className="tc-lcta-text">
                <h4>
                  Shunmugam Harikrishnan's <span>Background</span>
                </h4>
                <p>
                  Shunmugam holds a B.Tech degree in Information Technology from
                  Anna University. He has broad experience in the software and
                  information technology sector. He has served as a Business
                  Consultant, Data Analyst, Business Analyst and Project Manager
                  in various startup to mid-level companies. He also has a
                  proven record of achieving key milestones that have supported
                  and shaped the business of the companies he served.
                </p>
              </div>
              <div className="tc-lcta-connect">
                <img src={cta_connect} width={192} alt="Connect" />
                <div>
                  <span>Connect with</span>
                  <h4>Shunmugam Harikrishnan</h4>
                  <div className="mt-5">
                    <a
                      href="https://www.linkedin.com/in/theshunmugam/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <button className="reach-us linkedin">
                        <img src={linkedinIcon} width={16} alt="LinkedIn" />
                        LinkedIn
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm="12" lg="6" md="6">
              <div className="team-ceo-lc-join">
                <img src={join_img} alt="CSR" />
                <h4>
                  Join Us on the <span>Journey</span>
                </h4>
                <p>
                  We invite you to join Shunmugam Harikrishnan and the entire
                  Devship team as we embark on an exciting journey towards
                  providing better and more affordable software solutions for
                  the business and healthcare sector. Devship Pvt Ltd is
                  committed to delivering exceptional products/services and a
                  brighter future for all.
                </p>
                <div className="d-flex align-items-center justify-content-center mt-3">
                  <Link to="/contact">
                    <button className="reach-us">Reach us</button>
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}

export default MeetOurCEO;
