import React, { useEffect } from "react";

function GDPR() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = document.title = "GDPR Compliance • Devship";
  }, []);
  return (
    <section className="legal-section container">
      <h2>GDPR Compliance</h2>
      <div className="legal-wrap">
        <p>
          At Devship, accessible from{" "}
          <a
            href="https://www.devship.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.devship.in
          </a>
          , one of our main priorities is the privacy of our visitors. This
          Privacy Policy document contains types of information that is
          collected and recorded by Devship and how we use it.
        </p>
        <p>
          This policy sets out how Devship will process data and the rights of
          data subjects under data protection law, including in the EU the
          General Data Protection Regulation (GDPR).
        </p>
        <p>
          The following procedures and principles shall be followed at all times
          by company employees, agents, clients/customers, contractors, or other
          parties working in association with Devship Private Limited. If you
          have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us at{" "}
          <a
            href="mailto:hello@devship.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="highlight">hello@devship.in</span>.
          </a>
        </p>
        <h5>General Data Protection Regulation (GDPR) Compliance</h5>
        <p>We are the Data Controller of your information.</p>
        <span>
          Devship legal basis for collecting and using the personal information
          described in this Privacy Policy depends on the Personal Information
          we collect and the specific context in which we collect the
          information:
        </span>
        <ul>
          <li>Devship needs to perform a contract with you</li>
          <li>You have given Devship permission to do so</li>
          <li>
            Processing your personal information is in Devship legitimate
            interests
          </li>
          <li>Devship needs to comply with the law</li>
        </ul>
        <p>
          Devship will retain your personal information only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use your information to the extent necessary to comply with
          our legal obligations, resolve disputes, and enforce our policies.
        </p>
        <p>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. If you wish to be informed what
          Personal Information we hold about you and if you want it to be
          removed from our systems, please contact us.
        </p>
        <h5>Data Collection and Usage</h5>
        <p>
          Devship will retain your personal information only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use your information to the extent necessary to comply with
          our legal obligations, resolve disputes, and enforce our policies.
        </p>
        <h5>Types of Data Collected:</h5>
        <ul>
          <li>
            <span>Contact Information:</span> Name, email address, phone number.
          </li>
          <li>
            <span>Usage Data:</span> IP address, browser type, ISP,
            referring/exit pages, date/time stamp, and number of clicks.
          </li>
          <li>
            <span>Cookies and Tracking Technologies:</span> Used for
            personalising user experience and analysing website traffic.
          </li>
        </ul>
        <h5>Security Measures</h5>
        <p>
          We implement robust security measures to protect your personal data,
          including encryption, access controls, and regular security audits.
        </p>
        <h5>User Rights</h5>
        <p>
          If you are a resident of the European Economic Area (EEA), you have
          certain data protection rights. You have the right to access, correct,
          delete, or restrict the processing of your personal data. To exercise
          these rights, please contact us at hello@devship.in
        </p>
        <h5>Consent Management</h5>
        <p>
          By using our website, you consent to our Privacy Policy. You can
          withdraw your consent at any time by contacting us.
        </p>
        <h5>Third-Party Data Sharing</h5>
        <p>
          We may share your data with third-party service providers for the
          purposes of hosting, data analysis, and advertising. These parties are
          obligated to protect your data and use it only for the specified
          purposes.
        </p>
        <h5>Log Files</h5>
        <p>
          Devship follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          as part of hosting services' analytics. The information collected by
          log files includes IP addresses, browser type, ISP, date and time
          stamp, referring/exit pages, and number of clicks. These are not
          linked to any information that is personally identifiable. The purpose
          of the information is for analysing trends, administering the site,
          tracking users' movement on the website, and gathering demographic
          information.
        </p>
        <h5>Privacy Policies of Third-Party Services</h5>
        <p>
          Third-party ad servers or ad networks use technologies like cookies,
          JavaScript, or Web Beacons in their respective advertisements and
          links that appear on Devship, which are sent directly to users'
          browsers. They automatically receive your IP address when this occurs.
          These technologies are used to measure the effectiveness of their
          advertising campaigns and/or to personalise the advertising content
          that you see on websites you visit.
        </p>
        <p>
          Note that Devship has no access to or control over these cookies that
          are used by third-party advertisers
        </p>
        <h5>Third-Party Privacy Policies</h5>
        <p>
          Devship's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.
        </p>
        <p>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </p>
        <h5>Children's Information</h5>
        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>
        <p>
          Devship does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately, and we will do our best
          efforts to promptly remove such information from our records.
        </p>
        <h5>Contact Information</h5>
        <p>
          You can contact us via email at{" "}
          <a
            href="mailto:hello@devship.in"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="highlight">hello@devship.in</span>{" "}
          </a>
          for any futher queries or clarifications.
        </p>
        <h5>Consent</h5>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>
      </div>
    </section>
  );
}

export default GDPR;
