import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";

import "./AboutUs.css";

// Image Imports

import AboutUsImg from "../../../images/about.png";
import AboutUsGraphic from "../../../images/about_graphic.svg";
import Vision from "../../../images/about/vision.svg";
import Mission from "../../../images/about/mission.svg";
import Values from "../../../images/about/values.svg";

// End of Image Imports

function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Who we are • Devship";
  }, []);
  return (
    <>
      <section className="about-us">
        <div className="container-fluid">
          <Row>
            <Col sm="12" md="12" lg="5">
              <div className="about-content">
                <p>
                  We are a dynamic start-up incubated by a team of dedicated
                  professionals, aiming to provide top-tier technology services
                  from product design to deployment. Our services encompass
                  product development, management, marketing of web and mobile
                  applications, DevOps and Network Security, Technical
                  Consulting, and Cloud Management.
                </p>
                <p>
                  As an enterprise ourselves, we are your comprehensive solution
                  provider, backed by a team of skilled professionals who can
                  bring your dream projects to life at competitive prices. In
                  the ever-evolving tech landscape, we understand the challenges
                  of starting and establishing a brand. Our all-encompassing
                  services are designed to meet these needs, ensuring smooth
                  transitions from initial phases to overcoming future hurdles.
                </p>
              </div>
            </Col>
            <Col
              sm="12"
              md="12"
              lg="7"
              className="d-flex align-items-center justify-content-end"
            >
              <img
                loading="lazy"
                src={AboutUsImg}
                className="about-main-img lazy"
                data-mdb-lazy-src
                alt="About"
              />
            </Col>
          </Row>
        </div>
        <div className="container mobile-container">
          <div className="about-down-wrap">
            <Row>
              <Col
                sm="12"
                md="12"
                lg="3"
                className="d-flex align-items-center justify-content-center"
              >
                <img
                  loading="lazy"
                  src={AboutUsGraphic}
                  className="about-img lazy"
                  data-mdb-lazy-src
                  alt="About"
                />
              </Col>
              <Col sm="12" md="12" lg="9">
                <p>
                  We are committed to offering exceptional services while
                  maintaining low costs and high quality. Our goal is to
                  transform your dream plans into exquisite models, adding value
                  through creative ideas, designs, and processes that attract
                  clients and generate leads for successful business growth.
                </p>
                <p>
                  Our standardized project management processes ensure timely
                  delivery and customer satisfaction. We have successfully
                  completed 45+ projects, with over 8000 hours of coding and
                  3000+ graphics designed. Our client-centric approach ensures
                  flexibility to meet your needs, supported by productive small
                  teams that work synergistically to deliver significant
                  results.
                </p>
              </Col>
            </Row>
          </div>
          <p>
            We build the future with precise solutions for real-time problems,
            transforming them into prominent digital products, and becoming your
            best partners at work. Choosing the right company is crucial for
            achieving your desired project outcomes. We ensure the appropriate
            implementation of techniques and strategies to bring your vision to
            fruition.
          </p>
        </div>
        <div className="container-fluid">
          <div className="about-content-points">
            <div className="container mobile-container">
              <Row>
                <Col sm="12" md="4" lg="4">
                  <div className="about-content-right">
                    <div className="ac-img-holder mission">
                      <img
                        loading="lazy"
                        src={Mission}
                        className="lazy"
                        data-mdb-lazy-src
                        alt="Mission"
                      />
                    </div>
                    <div className="ac-text">
                      <h5>Our Mission</h5>
                      <p>
                        To transform the dream projects of our clients into
                        reality using superior quality technology at competitive
                        prices.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="4" lg="4">
                  <div className="about-content-right">
                    <div className="ac-img-holder vision">
                      <img
                        loading="lazy"
                        src={Vision}
                        className="lazy"
                        data-mdb-lazy-src
                        alt="Vision"
                      />
                    </div>
                    <div className="ac-text">
                      <h5>Our Vision</h5>
                      <p>
                        To become the most reliable digital and technological
                        service provider, turning our customers' plans into
                        successful models with a wide range of high-quality
                        services.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="4" lg="4">
                  <div className="about-content-right">
                    <div className="ac-img-holder values">
                      <img
                        loading="lazy"
                        src={Values}
                        className="lazy"
                        data-mdb-lazy-src
                        alt="Values"
                      />
                    </div>
                    <div className="ac-text">
                      <h5>Our Values</h5>
                      <p>
                        Affordability, Consistency, Customer-Centric Solutions
                        We strive to remain affordable, consistent, and driven
                        by customer needs. Our top-notch solutions provide
                        all-around services while adding value and creative
                        ideas to our clients' dream projects.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AboutUs;
