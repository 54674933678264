import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import OwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

// Image Imports

import ctaImage from "../../../images/cta.png";
import testiUp from "../../../images/icons/testi-up.svg";
import testiDown from "../../../images/icons/testi-down.svg";

// End of Image Imports

// Testimonials

const testimonials = [
  {
    company: "Cape Granite Works",
    name: "Nia Kambule",
    desg: "Director",
    testimonial:
      "Devship has been a pivotal partner for our business over the past year, assisting in the development and implementation of new business solutions. Their team is reliable, thorough, intelligent, accessible, excellent communicators, and very friendly. We highly recommend Devship to anyone seeking a highly productive and solution-driven team. We plan to continue our collaboration for the long term.",
    photo: "",
    female: false,
  },
  {
    company: "Trafford Care Consultancy",
    name: "James Whitaker",
    desg: "Director",
    testimonial:
      "I would like to express my satisfaction and happiness with the development of our web application. Devship and their development team did a highly professional job. We are pleased with the solution provided and the ongoing support throughout the project. I highly recommend Devship and look forward to working with them on future projects.",
    photo: "",
    female: false,
  },
  {
    company: "Famili Cloud",
    name: "Kwame Adeola",
    desg: "Director",
    testimonial:
      "They are experts in their field, delivering top-notch results with exceptional friendliness, reliability, and communication skills.",
    photo: "",
    female: false,
  },
  {
    company: "Escape Debt",
    name: "Thabo Mbeki",
    desg: "Director",
    testimonial:
      "We've been trying to put together a functional website since I began my business in 2021. I am happy to say we finally hired the Devship team, and they've worked closely with us throughout the process, staying on task, on target, and on budget. I also appreciate their quick and courteous responses. I highly recommend their service!",
    photo: "",
    female: false,
  },
];

function Testimonials() {
  let sliderCount = 0;
  const slides = document.querySelectorAll(".testi-client-item");
  slides.forEach((item, index) => {
    item.style.bottom = `${index * 100}%`;
  });
  const slideImages = () => {
    slides.forEach((slide) => {
      slide.style.transform = `translateY(${sliderCount * 100}%)`;
    });
  };

  const navigateUp = () => {
    sliderCount--;
    if (testimonials.length === sliderCount) {
      return;
    }
    slideImages();
  };

  const navigateDown = () => {
    if (sliderCount < 0 || sliderCount === testimonials.length) {
      return (sliderCount = 0);
    } else {
      sliderCount++;
    }
    slideImages();
  };

  const testimonialOptions = {
    loop: true,
    margin: 10,
    stagePadding: 5,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
    nav: true,
    autoplay: true,
    autoplayTimeout: 4000,
    autoplayHoverPause: true,
    dots: false,
  };

  return (
    <section className="testimonial-section">
      <div className="testimonial-content container">
        <div className="testi-left">
          <h4>What Our Clients Think About Us</h4>
          <div className="testi-cta-img">
            <img src={ctaImage} className="cta-image" alt="CTA" />
          </div>
          <h5>Customer Satisfaction at Its Best</h5>
          <h6>
            We have quickly earned the respect and trust of our clients, who are
            happy to work with us. Their positive feedback motivates us to
            continue delivering exceptional service.
          </h6>
        </div>
        <div className="testimonial-area-wrap">
          <Row>
            <Col
              sm="12"
              md="12"
              lg="5"
              className="d-flex align-items-center justify-content-center"
            >
              <h2>Let's See What Our Clients Say</h2>
            </Col>
            <Col sm="12" md="12" lg="7">
              <div className="testi-client-item-wrap">
                <OwlCarousel className="owl-theme" {...testimonialOptions}>
                  {testimonials.map((item, i) => {
                    return (
                      <div className="item">
                        <div className="testi-client-item" key={i}>
                          <h4>{item.company}</h4>
                          <h6>{item.testimonial}</h6>
                          <h5>
                            {item.name}, {item.desg}
                          </h5>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              </div>
            </Col>
          </Row>
          <div className="testi-item-controls">
            <span className="testi-control up" onClick={navigateUp}>
              <img src={testiUp} alt="Testimonials" />
            </span>
            <span className="testi-control down" onClick={navigateDown}>
              <img src={testiDown} alt="Testimonials" />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;
