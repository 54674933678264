import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import "./Howwework.css";

// Image Imports

import discovery from "../../../images/icons/howwework/discovery.svg";
import deployment from "../../../images/icons/howwework/deployment.svg";
import development from "../../../images/icons/howwework/development.svg";
import design from "../../../images/icons/howwework/design.svg";
import agile from "../../../images/icons/howwework/agile.svg";
import proc_client from "../../../images/icons/howwework/proc_client.svg";
import proc_experience from "../../../images/icons/howwework/proc_experience.svg";
import proc_post from "../../../images/icons/howwework/proc_post.svg";
import collaboration from "../../../images/icons/howwework/collaboration.svg";
import right_arrow_white from "../../../images/right_arrow_white.svg";

// Tech Logos

import angular from "../../../images/icons/tech_logos/angular.svg";
import aws from "../../../images/icons/tech_logos/aws.svg";
import css from "../../../images/icons/tech_logos/css.svg";
import docker from "../../../images/icons/tech_logos/docker.svg";
import express from "../../../images/icons/tech_logos/express.svg";
import flutter from "../../../images/icons/tech_logos/flutter.svg";
import gitlab from "../../../images/icons/tech_logos/gitlab.svg";
import html5 from "../../../images/icons/tech_logos/html5.svg";
import java from "../../../images/icons/tech_logos/java.svg";
import jenkins from "../../../images/icons/tech_logos/jenkins.svg";
import js from "../../../images/icons/tech_logos/js.svg";
import kotlin from "../../../images/icons/tech_logos/kotlin.svg";
import kubernetes from "../../../images/icons/tech_logos/kubernetes.svg";
import mongodb from "../../../images/icons/tech_logos/mongodb.svg";
import mysql from "../../../images/icons/tech_logos/mysql.svg";
import nodejs from "../../../images/icons/tech_logos/nodejs.svg";
import objectivec from "../../../images/icons/tech_logos/objectivec.svg";
import postgre from "../../../images/icons/tech_logos/postgre.svg";
import react from "../../../images/icons/tech_logos/react.svg";
import reactnative from "../../../images/icons/tech_logos/reactnative.svg";
import swift from "../../../images/icons/tech_logos/swift.svg";
import vue from "../../../images/icons/tech_logos/vue.svg";

// End of Image Imports

const methodContent = [
  {
    id: 1,
    title: "Discovery and Planning",
    content: `We start by understanding your business requirements and defining the project scope. Through collaborative planning sessions, we align our strategy with your goals to lay a solid foundation for success.`,
  },
  {
    id: 2,
    title: "Design and Prototyping",
    content: `Our team creates detailed prototypes and user flow diagrams to visualise the solution. This iterative process ensures that your feedback helps shape the final design.`,
  },
  {
    id: 3,
    title: "Agile Development Process",
    content: `We adopt Agile methodology in our projects, which allows for flexibility, rapid iterations, and continuous improvement. This approach enables us to adapt to changes quickly and deliver incremental value with each sprint.`,
  },
  {
    id: 4,
    title: "Development and Testing",
    content: `Using full-stack technologies, we build robust web and mobile applications. Rigorous testing phases ensure a high-quality, secure product that performs seamlessly.`,
  },
  {
    id: 5,
    title: "Development and Testing",
    content: `We handle the deployment process from start to finish, ensuring a smooth transition to the live environment. Our commitment extends beyond launch, offering continuous support and optimisation.`,
  },
];

const techStackContent = [
  {
    id: 1,
    title: "Frontend Technologies",
    content: [
      {
        title: "HTML5, CSS3, JavaScript",
        desc: `Building responsive and interactive user interfaces.`,
      },
      {
        title: "React, Angular, Vue.js",
        desc: `Developing dynamic, single-page applications with modern JavaScript frameworks.`,
      },
    ],
  },
  {
    id: 2,
    title: "Backend Technologies",
    content: [
      {
        title: " Node.js, Express.js",
        desc: `A powerful combination for creating scalable server-side applications and APIs. For secure, robust, and scalable backend systems tailored to complex business needs.`,
      },
    ],
  },
  {
    id: 3,
    title: "Mobile Technologies",
    content: [
      {
        title: "Native iOS Development (Swift, Objective-C)",
        desc: `High-performance applications built specifically for the Apple ecosystem.`,
      },
      {
        title: "Native Android Development (Kotlin, Java)",
        desc: `Powerful, responsive applications developed for Android devices.`,
      },
      {
        title: "Cross-Platform Development (React Native, Flutter)",
        desc: `Leading frameworks for building apps that provide native-like performance and user experience on both iOS and Android.`,
      },
    ],
  },
  {
    id: 4,
    title: "Databases",
    content: [
      {
        title: "MySQL, PostgreSQL",
        desc: `Reliable relational databases for structured data management.`,
      },
      {
        title: "MongoDB",
        desc: `A flexible NoSQL database option for efficiently handling large volumes of unstructured data.`,
      },
    ],
  },
  {
    id: 5,
    title: "Cloud and DevOps Tools",
    content: [
      {
        title: "AWS (Amazon Web Services)",
        desc: `Scalable cloud infrastructure to support businesses of all sizes, ensuring high availability and security.`,
      },
      {
        title: "Docker, Kubernetes",
        desc: `Containerisation and orchestration tools for efficient application deployment and scaling.`,
      },
      {
        title: "CI/CD Pipelines (Jenkins, GitLab CI)",
        desc: `Automated workflows for continuous integration and continuous deployment to streamline the development process.`,
      },
    ],
  },
];

const frontend = [
  {
    id: 1,
    name: "HTML 5",
    imgURL: html5,
  },
  {
    id: 2,
    name: "CSS",
    imgURL: css,
  },
  {
    id: 3,
    name: "JavaScript",
    imgURL: js,
  },
  {
    id: 4,
    name: "React",
    imgURL: react,
  },
  {
    id: 5,
    name: "Angular",
    imgURL: angular,
  },
  {
    id: 6,
    name: "Vue JS",
    imgURL: vue,
  },
];

const backend = [
  {
    id: 7,
    name: "Node JS",
    imgURL: nodejs,
  },
  {
    id: 7,
    name: "Express JS",
    imgURL: express,
  },
];

const mobileTech = [
  {
    id: 8,
    name: "Swift",
    imgURL: swift,
  },
  {
    id: 9,
    name: "Objective C",
    imgURL: objectivec,
  },
  {
    id: 10,
    name: "Kotlin",
    imgURL: kotlin,
  },
  {
    id: 11,
    name: "Java",
    imgURL: java,
  },
  {
    id: 12,
    name: "React Native",
    imgURL: reactnative,
  },
  {
    id: 13,
    name: "Flutter",
    imgURL: flutter,
  },
];

const databaseTech = [
  {
    id: 14,
    name: "mySQL",
    imgURL: mysql,
  },
  {
    id: 15,
    name: "PostGreSQL",
    imgURL: postgre,
  },
  {
    id: 16,
    name: "Mongo DB",
    imgURL: mongodb,
  },
];

const cloudTech = [
  {
    id: 17,
    name: "Amazon Web Services",
    imgURL: aws,
  },
  {
    id: 18,
    name: "Docker",
    imgURL: docker,
  },
  {
    id: 19,
    name: "Kubernetes",
    imgURL: kubernetes,
  },
  {
    id: 20,
    name: "Jenkins",
    imgURL: jenkins,
  },
  {
    id: 21,
    name: "GitLab",
    imgURL: gitlab,
  },
];

function HowWeWork() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "How we work • Devship";
    runEffect();
  }, []);

  const runEffect = async () => {
    first();
    await delay(5000);
    second();
    await delay(5000);
    third();
    await delay(5000);
    fourth();
    await delay(5000);
    fifth();
    await delay(5000);
    first();
    await delay(5000);
    second();
    await delay(5000);
    third();
    await delay(5000);
    fourth();
    await delay(5000);
    fifth();
    await delay(5000);
    first();
  };

  const [action, setAction] = useState("");

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const first = () => {
    setAction("first");
  };
  const second = () => {
    setAction("second");
  };
  const third = () => {
    setAction("third");
  };
  const fourth = () => {
    setAction("fourth");
  };
  const fifth = () => {
    setAction("fifth");
  };

  const [open, setOpen] = useState(1);
  const [opacityActive, setOpacityActive] = useState(1);
  const toggle = (id) => {
    setOpacityActive(id);
    if (open === id) {
      setOpen();
      console.log(opacityActive);
    } else {
      setOpen(id);
    }
  };
  return (
    <section className="howwework-section">
      <div className="howwework-header">
        <div className="container">
          <h2>How We Work</h2>
          <h4>Transforming Business Challenges into Digital Solutions</h4>
          <h6>
            At Devship, we specialise in turning business challenges into
            innovative digital solutions. Our approach is designed to ensure
            clarity, collaboration, and quality at every stage, delivering
            exceptional results that drive success.
          </h6>
        </div>
      </div>
      {/* Methodology */}
      <div className="howwework-method-wrap">
        <h4>Our Methodology</h4>
        <div className="container">
          <div className="hwk-circle-wrap">
            <div className="hwk-circle">
              {action === "first" ? (
                <h5>
                  We start by understanding your business requirements and
                  defining the project scope. Through collaborative planning
                  sessions, we align our strategy with your goals to lay a solid
                  foundation for success.
                </h5>
              ) : action === "second" ? (
                <h5>
                  Our team creates detailed prototypes and user flow diagrams to
                  visualise the solution. This iterative process ensures that
                  your feedback helps shape the final design.
                </h5>
              ) : action === "third" ? (
                <h5>
                  We adopt Agile methodology in our projects, which allows for
                  flexibility, rapid iterations, and continuous improvement.
                  This approach enables us to adapt to changes quickly and
                  deliver incremental value with each sprint.
                </h5>
              ) : action === "fourth" ? (
                <h5>
                  Using full-stack technologies, we build robust web and mobile
                  applications. Rigorous testing phases ensure a high-quality,
                  secure product that performs seamlessly.
                </h5>
              ) : (
                <h5>
                  We handle the deployment process from start to finish,
                  ensuring a smooth transition to the live environment. Our
                  commitment extends beyond launch, offering continuous support
                  and optimisation.
                </h5>
              )}
            </div>
            <div className="hwk-circle-items-wrap">
              <div className="hwk-circle-row">
                {/* Discovery */}
                <div
                  className={`hwk-item ${action === "first" ? "active" : ""}`}
                >
                  <h5>Discovery and Planning</h5>
                  <div className="hwk-img">
                    {/* <img src={discovery} alt="Discovery and Planning" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.969"
                      height="15.969"
                      viewBox="0 0 15.969 15.969"
                    >
                      <path
                        data-name="Path 22403"
                        d="M4-7.5a5.245 5.245 0 0 0-.75-2.75 5.418 5.418 0 0 0-2-2A5.464 5.464 0 0 0-1.5-13a5.464 5.464 0 0 0-2.75.75 5.418 5.418 0 0 0-2 2A5.245 5.245 0 0 0-7-7.5a5.245 5.245 0 0 0 .75 2.75 5.418 5.418 0 0 0 2 2A5.464 5.464 0 0 0-1.5-2a5.464 5.464 0 0 0 2.75-.75 5.418 5.418 0 0 0 2-2A5.245 5.245 0 0 0 4-7.5zM2.719-2.562A6.532 6.532 0 0 1-1.5-1a6.455 6.455 0 0 1-4.594-1.906A6.455 6.455 0 0 1-8-7.5a6.455 6.455 0 0 1 1.906-4.594A6.455 6.455 0 0 1-1.5-14a6.455 6.455 0 0 1 4.594 1.906A6.455 6.455 0 0 1 5-7.5a6.532 6.532 0 0 1-1.562 4.219L7.969 1.25l-.719.719z"
                        transform="translate(8 14)"
                        style={{ fill: "#212121" }}
                      />
                    </svg>
                  </div>
                </div>
                {/* Deployment */}
                <div
                  className={`hwk-item ${action === "fifth" ? "active" : ""}`}
                >
                  <div className="hwk-img">
                    {/* <img src={deployment} alt="Deployment and Maintenance" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        data-name="Path 22401"
                        d="M-3-9v-1.5l-2.906-2.187-.781.781L-4.5-9zm1 0v.281l3.094 3.125.438-.437.718-.719.719.719 4.312 4.312L8-1l-.719.719-1.562 1.562L5 2l-.719-.719-4.312-4.312-.719-.719.719-.719.438-.437L-2.719-8H-5l-3-4 2-2 4 3zM5 .594 6.594-1 2.25-5.344.656-3.75zM-7.281-2.219l4.469-4.469.719.719L-6.594-1.5-4.5.594l3.344-3.344.688.719-3.313 3.312L-4.5 2l-.719-.719-2.062-2.062L-8-1.5zM7-9a3.744 3.744 0 0 0-.125-.969L5.219-8.281 4.906-8H2v-2.906l.281-.312 1.688-1.656A3.744 3.744 0 0 0 3-13a3.882 3.882 0 0 0-2 .531 4.413 4.413 0 0 0-1.437 1.407l-.781-.594a4.7 4.7 0 0 1 1.781-1.719A4.825 4.825 0 0 1 3-14a5.087 5.087 0 0 1 1.781.313 5.128 5.128 0 0 1 .938.5l-.75.719L3-10.5V-9h1.5l1.969-1.969.75-.75a6.559 6.559 0 0 1 .469.938A5.087 5.087 0 0 1 8-9a4.812 4.812 0 0 1-.656 2.469A5.117 5.117 0 0 1 5.656-4.75l-.718-.75a4.137 4.137 0 0 0 1.5-1.437A3.83 3.83 0 0 0 7-9zM-4.25-1.031l-.719-.719.719-.719.719.719z"
                        transform="translate(8 14)"
                        style={{ fill: "#212121" }}
                      />
                    </svg>
                  </div>
                  <h5>Deployment and Maintenance</h5>
                </div>
              </div>
              <div className="hwk-circle-row second">
                {/* Design */}
                <div
                  className={`hwk-item ${action === "second" ? "active" : ""}`}
                >
                  <h5>Design and Prototyping</h5>
                  <div className="hwk-img">
                    {/* <img src={design} alt="Design and Prototyping" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        data-name="Path 22402"
                        d="M-8-1a2.99 2.99 0 0 0 .875 2.125A2.99 2.99 0 0 0-5 2H8v-6H2.375l3.594-3.594.375-.375-.375-.344-3.531-3.531-.344-.375-.344.375L-2-8.125V-14h-6v13zM7 1h-9.625l4-4H7zM4.906-7.969-2-1.031v-5.656l4.094-4.094zM-5 1A2.02 2.02 0 0 1-6.406.406 2.02 2.02 0 0 1-7-1v-4h4v4A2.02 2.02 0 0 1-3.594.406 2.02 2.02 0 0 1-5 1zm-2-7v-3h4v3zm0-4v-3h4v3zm2 9.75A.747.747 0 0 0-4.25-1 .747.747 0 0 0-5-1.75a.747.747 0 0 0-.75.75.747.747 0 0 0 .75.75z"
                        transform="translate(8 14)"
                        style={{ fill: "#212121" }}
                      />
                    </svg>
                  </div>
                </div>
                {/* Development */}
                <div
                  className={`hwk-item ${action === "fourth" ? "active" : ""}`}
                >
                  <div className="hwk-img">
                    {/* <img src={development} alt="Development and Testing" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="14"
                      viewBox="0 0 20 14"
                    >
                      <path
                        data-name="Path 22400"
                        d="M7 0H-7v-9h-1V1H8V-9H7zM-8.094-12H8.094l.906.906V-11H-9v-.094zM10-10v-1.5L8.5-13h-17l-1.5 1.5v1.5h20zM-.781-3l-.375-.344L-2.781-5l1.625-1.656L-.781-7l-.719-.719-.344.375-2 2-.375.344.375.344 2 2 .344.375zm1.938-.344L.781-3l.719.719.344-.375 2-2L4.219-5l-.375-.344-2-2-.344-.375L.781-7l.375.344L2.781-5z"
                        transform="translate(10 13)"
                        style={{ fill: "#212121" }}
                      />
                    </svg>
                  </div>
                  <h5>Development and Testing</h5>
                </div>
              </div>
              <div className="hwk-circle-row third">
                {/* Agile */}
                <div
                  className={`hwk-item ${action === "third" ? "active" : ""}`}
                >
                  <div className="hwk-img">
                    {/* <img src={agile} alt="Agile Development Process" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        data-name="Path 22399"
                        d="M5-13v2h2v-2zm-1-1h4v4H4v-4zm-3.062.063.375.313 1.5 1.25.468.374-.469.375-1.5 1.25-.375.344-.625-.781.375-.312.438-.375H-7v5H8v7H5V2H1v-4h4v1.5h2v-5H-8v-7h9.125l-.437-.375-.375-.312zM-4-1v.5h2.125l-.437-.375-.375-.312.625-.75.375.313 1.5 1.25L.281 0l-.468.375-1.5 1.25-.375.344-.625-.781.375-.312.437-.376H-4V2h-4v-4h4zm-1 0h-2v2h2zm7 0v2h2v-2z"
                        transform="translate(8 14)"
                        style={{ fill: "#212121" }}
                      />
                    </svg>
                  </div>
                  <h5>Agile Development Process</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Tech Stack */}
      <div className="hww-tech-stack-section">
        <div className="container">
          <h4>Our Tech Stack</h4>
          <h5>
            To deliver high-performance, scalable solutions, we utilise a
            comprehensive tech stack that covers both front-end and back-end
            development, mobile applications, and DevOps:
          </h5>
          <Row>
            <Col sm="12" lg="5" md="12">
              <div className="hww-tech-stack-accordion" id="techStackAccordion">
                <Accordion open={open} toggle={toggle} defaultOpen={1}>
                  {techStackContent.map((item) => {
                    return (
                      <AccordionItem>
                        <AccordionHeader targetId={item.id}>
                          {item.title}
                        </AccordionHeader>
                        <AccordionBody accordionId={item.id}>
                          {item.content.map((content) => {
                            return (
                              <div className="hwwt-text">
                                <h6>{content.title}</h6>
                                <p>{content.desc}</p>
                              </div>
                            );
                          })}
                        </AccordionBody>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </div>
            </Col>
            <Col sm="12" lg="7" md="12">
              <div className="tech-stack-logos-wrap">
                {frontend.map((logo) => {
                  return (
                    <div className="tsl-item" data-content={logo.name}>
                      <img
                        src={logo.imgURL}
                        alt={logo.name}
                        style={{
                          opacity: opacityActive === 1 ? "1" : "0.15",
                        }}
                      />
                    </div>
                  );
                })}
                {backend.map((logo) => {
                  return (
                    <div className="tsl-item" data-content={logo.name}>
                      <img
                        src={logo.imgURL}
                        alt={logo.name}
                        style={{ opacity: opacityActive === 2 ? "1" : "0.15" }}
                      />
                    </div>
                  );
                })}
                {mobileTech.map((logo) => {
                  return (
                    <div className="tsl-item" data-content={logo.name}>
                      <img
                        src={logo.imgURL}
                        alt={logo.name}
                        style={{ opacity: opacityActive === 3 ? "1" : "0.15" }}
                      />
                    </div>
                  );
                })}
                {databaseTech.map((logo) => {
                  return (
                    <div className="tsl-item" data-content={logo.name}>
                      <img
                        src={logo.imgURL}
                        alt={logo.name}
                        style={{ opacity: opacityActive === 4 ? "1" : "0.15" }}
                      />
                    </div>
                  );
                })}
                {cloudTech.map((logo) => {
                  return (
                    <div className="tsl-item" data-content={logo.name}>
                      <img
                        src={logo.imgURL}
                        alt={logo.name}
                        style={{ opacity: opacityActive === 5 ? "1" : "0.15" }}
                      />
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* Process Points */}
      <div className="hww-process-points">
        <div className="container">
          <Row>
            <Col sm="12" lg="4" md="6">
              <div className="hww-proc-item">
                <div className="hww-proc-img">
                  <img src={proc_client} alt="Client" />
                </div>
                <h4>Client Collaboration</h4>
                <h6>
                  We believe in a transparent, client-focused approach. Regular
                  updates, feedback sessions, and collaborative reviews keep you
                  involved and informed throughout the project lifecycle,
                  ensuring the final product exceeds your expectations.
                </h6>
              </div>
            </Col>
            <Col sm="12" lg="4" md="6">
              <div className="hww-proc-item">
                <div className="hww-proc-img">
                  <img src={proc_experience} alt="Client" />
                </div>
                <h4>Post-Launch Excellence</h4>
                <h6>
                  Our partnership does not end at deployment. We provide ongoing
                  optimisation, feature updates, and comprehensive support to
                  ensure your digital solutions remain aligned with your
                  evolving business needs.
                </h6>
              </div>
            </Col>
            <Col sm="12" lg="4" md="6">
              <div className="hww-proc-item">
                <div className="hww-proc-img">
                  <img src={proc_post} alt="Client" />
                </div>
                <h4>Experience Across Industries</h4>
                <h6>
                  With experience across multiple industries, including
                  healthcare, finance, retail, and technology, we have the
                  expertise to deliver tailored solutions that address unique
                  business challenges.
                </h6>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* Join us */}
      <div className="container">
        <div className="join-us-wrapper">
          <Row>
            <Col sm="12" md="12" lg="6" className="d-flex align-items-center">
              <div>
                <h2>Ready to Collaborate?</h2>
                <h6>
                  Ready to innovate and grow with a trusted partner? Get in
                  Touch with us today to see how we can help transform your
                  business.
                </h6>
                <Link to="/contact">
                  <button className="common-btn">
                    Reach us
                    <div className="common-btn-img">
                      <img src={right_arrow_white} alt="Right" />
                    </div>
                  </button>
                </Link>
              </div>
            </Col>
            <Col
              sm="12"
              md="12"
              lg="6"
              className="d-flex align-items-center justify-content-center"
            >
              <img
                src={collaboration}
                className="join-cta-img"
                alt="Collaborate"
              />
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
}

export default HowWeWork;
