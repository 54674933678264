import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Navbar, NavbarToggler, Collapse, Nav, NavItem } from "reactstrap";

import "./Common.css";

// Image Imports

import Logo from "../../../images/logo.svg";
// import Logo from "../../../images/LogoChristmas.svg";

// End of Image Imports

function Header() {
  const [nav, setNav] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const handleScroll = () => {
    const position = window.scrollY;
    if (position > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const noRefCheck = () => {
    setNav(!nav);
  };

  return (
    <div>
      <Navbar
        color={`${scrolled ? "white" : ""}`}
        expand="md"
        fixed="top"
        container
        light
      >
        <Link to="/" className="p-0 navbar-brand" onClick={noRefCheck}>
          <img
            loading="lazy"
            src={Logo}
            className="lazy"
            data-mdb-lazy-src
            alt="Devship"
          />
        </Link>
        <NavbarToggler className="nav-toggler" onClick={noRefCheck} />
        <Collapse
          className={`justify-content-end ${nav ? "d-block" : ""}`}
          navbar
        >
          <Nav className="mt-1 align-items-center" navbar>
            <NavItem>
              <NavLink className={`nav-link`} to="/" onClick={noRefCheck}>
                Home
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`nav-link`}
                to="/services"
                onClick={noRefCheck}
              >
                What We Offer
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`nav-link`}
                to="/portfolio"
                onClick={noRefCheck}
              >
                Portfolio
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`nav-link`}
                to="/howwework"
                onClick={noRefCheck}
              >
                How We Work
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={`nav-link`} to="/about" onClick={noRefCheck}>
                Who We Are
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={`nav-link`} to="/team" onClick={noRefCheck}>
                About Our Team
              </NavLink>
            </NavItem>
            <NavItem>
              <a
                className="nav-link"
                href="https://devship.keka.com/careers/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Careers
              </a>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={`nav-link`}
                to="/team/CEO"
                onClick={noRefCheck}
              >
                Meet our CEO
              </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                id="contactLink"
                className={`nav-link`}
                to="/contact"
                onClick={noRefCheck}
              >
                Get in Touch
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Header;
