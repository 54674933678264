import React, { useState } from "react";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { Link } from "react-router-dom";

import GoTop from "../Common/GoTop";

// Image Imports

import Logo from "../../../images/logo_icon.svg";
import Phone from "../../../images/icons/footer/Phone.svg";
import Email from "../../../images/icons/footer/Email.svg";
import Location from "../../../images/icons/footer/Location.svg";
import WhatsApp from "../../../images/social/whatsapp.svg";
import Facebook from "../../../images/social/facebook.svg";
import Linkedin from "../../../images/social/linkedin.svg";
import Insta from "../../../images/social/instagram.svg";
import Dribbble from "../../../images/social/dribbble.svg";

// End of Image Imports
function Footer() {
  // Tabs
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <footer>
      <div className="container">
        <Row>
          <Col sm="12" md="6" lg="4">
            <img
              loading="lazy"
              src={Logo}
              className="lazy"
              data-mdb-lazy-src
              width={40}
              height={40}
              alt="Devship"
            />
            <h6 className="mb-0">Devship Pvt. Ltd.</h6>
            <p data-tagline>Think Tech, Choose Us.</p>
            <div className="footer-contact-details">
              <p>
                <a href="tel:+914440423120">
                  <img
                    loading="lazy"
                    src={Phone}
                    className="lazy"
                    data-mdb-lazy-src
                    alt="Phone"
                  />
                  +91 81489 84627
                </a>
              </p>
              {/* <p>
                <a
                  href="http://api.whatsapp.com/send?phone=918148984627"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={WhatsApp} className="lazy" data-mdb-lazy-src alt="WhatsApp" />
                  +91 81489 84627
                </a>
              </p> */}
              <p className="d-flex align-items-center gap-2">
                <a href="mailto:hello@devship.in">
                  <img
                    loading="lazy"
                    src={Email}
                    className="lazy"
                    data-mdb-lazy-src
                    alt="Email"
                  />
                  hello@devship.in
                </a>
              </p>
              <p className="d-flex align-items-center gap-2">
                <a
                  href="https://goo.gl/maps/kx9owcZRJm6TkfG59"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="align-items-start"
                >
                  <img
                    loading="lazy"
                    src={Location}
                    style={{ marginTop: "3px" }}
                    alt="Location"
                    className="lazy"
                    data-mdb-lazy-src
                  />
                  Awfis, Ground Floor, Spero Primus Building, <br /> Door No. SP
                  – 7A, SIDCO Industrial Estate, <br />
                  Guindy, Chennai - 600 032, Tamil Nadu, India
                </a>
              </p>
              {/* <Nav tabs>
                <NavItem>
                  <NavLink
                    className={`${activeTab === "1" ? "active" : ""}`}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    India
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={`${activeTab === "2" ? "active" : ""}`}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    United Kingdom
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <p className="addr-text">
                    <a
                      href="https://goo.gl/maps/kx9owcZRJm6TkfG59"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="align-items-start"
                    >
                      <img
                        loading="lazy"
                        src={Location}
                        style={{ marginTop: "3px" }}
                        alt="Location"
                        className="lazy"
                        data-mdb-lazy-src
                      />
                  Awfis, Ground Floor, Spero Primus Building, <br /> Door No. SP
                  – 7A, Guindy Industrial Estate Guindy, <br /> Chennai - 600 032,
                  Tamil Nadu, India
                    </a>
                  </p>
                </TabPane>
                <TabPane tabId="2">
                  <p className="addr-text">
                    <a
                      href="https://goo.gl/maps/kx9owcZRJm6TkfG59"
                      rel="noopener noreferrer"
                      target="_blank"
                      className="align-items-start"
                    >
                      <img
                        loading="lazy"
                        src={Location}
                        style={{ marginTop: "3px" }}
                        alt="Location"
                        className="lazy"
                        data-mdb-lazy-src
                      />
                      #302, Kemp House, 158, City Road, <br />
                      London, United Kingdom EC1V 2NX.
                    </a>
                  </p>
                </TabPane>
              </TabContent> */}
            </div>
          </Col>
          <Col sm="12" md="3" lg="2">
            <h6 data-title>Quick Links</h6>
            <ul className="footer-links">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/services">What we offer</Link>
              </li>
              <li>
                <Link to="/portfolio">Portfolio</Link>
              </li>
              <li>
                <a
                  href="https://devship.keka.com/careers/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Careers
                </a>
              </li>
              {/* <li>
                <a
                  href="https://devship.keka.com/careers/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Careers
                </a>
              </li> */}
              {/* <li>
                <Link to="/team/ceo">Meet our CEO</Link>
              </li> */}
              <li>
                <Link to="/contact">Get in touch</Link>
              </li>
            </ul>
          </Col>
          <Col sm="12" md="3" lg="2">
            <h6 data-title>About Devship</h6>
            <ul className="footer-links">
              <li>
                <Link to="/howwework">How we work</Link>
              </li>
              <li>
                <Link to="/about">Who we are</Link>
              </li>
              <li>
                <Link to="/team">About our team</Link>
              </li>
            </ul>
          </Col>
          <Col sm="12" md="3" lg="2">
            <h6 data-title>Legal</h6>
            <ul className="footer-links">
              <li>
                <Link to="/terms">Terms &amp; Conditions</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/GDPR">GDPR Compliance</Link>
              </li>
            </ul>
          </Col>
          <Col sm="12" md="6" lg="2">
            <h6 data-title>Follow us</h6>
            <div className="social-links">
              <a
                href="https://www.linkedin.com/company/devship-india-pvt-ltd/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  loading="lazy"
                  src={Linkedin}
                  className="lazy"
                  data-mdb-lazy-src
                  alt="Linkedin"
                />
              </a>
              <a
                href="https://wa.me/+918148984627"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  loading="lazy"
                  src={WhatsApp}
                  className="lazy"
                  data-mdb-lazy-src
                  alt="WhatsApp"
                />
              </a>
              <a
                href="https://www.instagram.com/devship.in/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  loading="lazy"
                  src={Insta}
                  className="lazy"
                  data-mdb-lazy-src
                  alt="Instagram"
                />
              </a>
              <a
                href="https://dribbble.com/devship_in/about"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  loading="lazy"
                  src={Dribbble}
                  className="lazy"
                  data-mdb-lazy-src
                  alt="Dribbble"
                />
              </a>
              {/* <a
                href="https://www.facebook.com"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img
                  src={Facebook}
                  className="lazy"
                  data-mdb-lazy-src
                  alt="Facebook"
                />
              </a> */}
            </div>
          </Col>
        </Row>
        <div className="copy-text">
          <p>
            {new Date().getFullYear()} &copy; Devship Pvt. Ltd. All Rights
            Reserved
          </p>
          <span>
            Registered under the Ministry of Corporate Affairs, Government of
            India.
          </span>
        </div>
      </div>
      <GoTop />
    </footer>
  );
}

export default Footer;
