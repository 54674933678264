import React, { useRef, useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Assistance from "../Common/Assistance";
import emailjs from "emailjs-com";
import toast from "react-hot-toast";

import "./Home.css";
import Testimonials from "../Common/Testimonials";

import useCustomForm from "../../../helpers/form/CustomForm";

// Image Imports

import bgVideo from "../../../images/main_bg.webm";
import projectDone from "../../../images/projects_done.svg";
import projectDoneSelected from "../../../images/projects_done_selected.svg";
import projectDoneTick from "../../../images/projects_completed_tick.svg";
import hoursCoding from "../../../images/hours_coding.svg";
import graphicsDesigned from "../../../images/graphics_designed.svg";
import qualityAssurance from "../../../images/quality_assurance.svg";
import qualityAssuranceSelected from "../../../images/quality_sssurance_selected.svg";
import happyClientsHeart from "../../../images/happy_clients_heart.svg";
import project_cta from "../../../images/project_cta_flight.svg";
import design_dev from "../../../images/design_dev.svg";
import consult_plan from "../../../images/consult_plan.svg";
import deploy_manage from "../../../images/deploy_manage.svg";
import market_reach from "../../../images/market_reach.svg";
import whychooseus from "../../../images/whychooseus.png";
import client_centric from "../../../images/why-choose-us/01_CC.svg";
import prod_small_team from "../../../images/why-choose-us/02_PS.svg";
import expert_skill from "../../../images/why-choose-us/03_ES.svg";
import quick_adapt from "../../../images/why-choose-us/04_QA.svg";
import Cam from "../../../images/projects/tcc_home.svg";
import Edr from "../../../images/projects/Edr_home.png";
import Kg from "../../../images/projects/Kg_home.png";
import Sp from "../../../images/projects/Sp_home.png";
import Dr from "../../../images/projects/Dr_home.png";
import laptopImage from "../../../images/laptop.png";
import newsletter_img from "../../../images/newsletter_img.png";
import right_arrow from "../../../images/right_arrow.svg";
import right_arrow_white from "../../../images/right_arrow_white.svg";
import AssistanceImg from "../../../images/Assistance.svg";

// End of Image Imports

const projectsList = [
  {
    id: 1,
    title: "Escape Debt Review",
    tagline: "Quick Debt Relief",
    tagColor: "#f7941e",
    desc: `Escape Debt Review helps customers eliminate debt review struggles through an easy online process.`,
    imgURL: Edr,
  },
  {
    id: 2,
    title: "Trafford Care Consultancy",
    tagline: "Streamlined Healthcare Solutions",
    tagColor: "#005eb8",
    desc: `Trafford Care Consultancy (TCC) specialises in aiding healthcare agencies to establish and grow in the UK.`,
    imgURL: Cam,
  },
  {
    id: 3,
    title: "Cape Granite Works",
    tagline: "Premium Granite Worktops",
    tagColor: "#212121",
    desc: `Cape Granite Works offers top-quality granite worktops in Cape Town, showcasing a wide range of products and services.`,
    imgURL: Kg,
  },
  {
    id: 4,
    title: "Southern Packaging",
    tagline: "Showcase Your Products",
    tagColor: "#eecc35",
    desc: `Southern Packaging presents its diverse product range clearly and attractively.`,
    imgURL: Sp,
  },
  {
    id: 5,
    title: "Refurnish",
    tagline: "Refurbished Product Sales",
    tagColor: "#019345",
    desc: `A new platform will facilitate the sale of refurbished products online, improving customer access and sales efficiency.`,
    imgURL: Dr,
  },
];

const homeAboutList = [
  {
    id: 1,
    title: "Design & Development",
    tagline: "Innovative web and mobile solutions",
    imgURL: design_dev,
    keyword: "design",
  },
  {
    id: 2,
    title: "Consultation & Planning",
    tagline: "Tailored strategies to kickstart your project",
    imgURL: consult_plan,
    keyword: "consult",
  },
  {
    id: 3,
    title: "Deployment & Management",
    tagline: "Seamless integration and ongoing support",
    imgURL: deploy_manage,
    keyword: "deploy",
  },
  {
    id: 4,
    title: "Marketing & Reach",
    tagline: "Effective strategies to target your audience",
    imgURL: market_reach,
    keyword: "market",
  },
];

const initialValues = {
  email: "",
};

function Home() {
  const sectionRefEdr = useRef();
  const sectionRefCam = useRef();
  const sectionRefKing = useRef();
  const sectionRefSouth = useRef();
  const sectionRefRefurn = useRef();
  const [elementIsEdr, setElementIsEdr] = useState();
  const [elementIsCam, setElementIsCam] = useState();
  const [elementIsKing, setElementIsKing] = useState();
  const [elementIsSouth, setElementIsSouth] = useState();
  const [elementIsRefurn, setElementIsRefurn] = useState();
  const [changeScroll, setChangeScroll] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    values: inputs,
    handleChange,
    setValues,
  } = useCustomForm({
    initialValues,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Devship";
    // EDR
    const observerEdr = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setElementIsEdr(entry.isIntersecting);
    });
    observerEdr.observe(sectionRefEdr.current);
    // CAM
    const observerCam = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setElementIsCam(entry.isIntersecting);
    });
    observerCam.observe(sectionRefCam.current);
    // Kingdom
    const observerKing = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setElementIsKing(entry.isIntersecting);
    });
    observerKing.observe(sectionRefKing.current);
    // Southern Packaging
    const observerSouth = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setElementIsSouth(entry.isIntersecting);
    });
    observerSouth.observe(sectionRefSouth.current);
    // Refurnish
    const observerRefurn = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setElementIsRefurn(entry.isIntersecting);
    });
    observerRefurn.observe(sectionRefRefurn.current);

    const watchScroll = () => {
      window.addEventListener("scroll", handleScroll);
    };
    watchScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const scrollPos = window.scrollY;
    if (scrollPos > 20) {
      setChangeScroll(true);
    } else {
      setChangeScroll(false);
    }
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const { email } = inputs;

  const subForm = useRef();

  const handleSubSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      return toast.error("Please provide us your Email Address", {
        id: "email",
      });
    } else if (!validateEmail(email)) {
      return toast.error("Seems like an invalid email", {
        id: "email",
      });
    }
    setLoading(true);
    emailjs
      .sendForm(
        "service_kyn7jnv",
        "template_6jhtuvl",
        subForm.current,
        "m7ZuMER00eb3yeouD"
      )
      .then(
        () => {
          setTimeout(() => setLoading(false), 3000);
          toast.success("Submitted Successfully!");
        },
        (error) => {
          toast.error("Sorry, try again!", error);
        }
      );
    setValues(initialValues);
  };

  return (
    <>
      {/* Main */}
      <div className="main-container">
        <main className="main-section">
          <div className="video-bg">
            <video id="video-main" autoPlay loop muted>
              <source src={bgVideo} type="video/webm" />
            </video>
          </div>
          <div className="content container">
            <div className="content-text">
              <h1>
                Empowering your <span data-block>Business</span>
              </h1>
              <h2>with Cutting-Edge Technology</h2>
              <h5 className="subtitle">
                We provide top-tier technology services, including{" "}
                <span data-blue>Web and Mobile App Development</span>,{" "}
                <span data-red>Design</span>, <span data-green>Deployment</span>
                , <span data-purple>Maintenance</span>, and{" "}
                <span data-teal>Targeted Marketing</span>.
              </h5>
              <div className="home-reach-btn-wrap">
                <Link to="/contact">
                  <button className="common-btn">
                    Reach Us
                    <div className="common-btn-img">
                      <img src={right_arrow_white} alt="Right" />
                    </div>
                  </button>
                </Link>
              </div>
            </div>
            <div className="next-section-line-wrap">
              <div className={`nsl-circle ${changeScroll ? "scroll-in" : ""}`}>
                <div className="nsl-circle-small"></div>
              </div>
              <div className="nsl-line"></div>
            </div>
          </div>
        </main>
      </div>
      {/* What we do */}
      <section className="about-section">
        <div className="container">
          <div className="sec-header">
            <h4>Jumpstart Your Business with Our Expertise</h4>
            <h6>
              Starting a business is challenging. Have a dream project but
              unsure how to begin? We offer stellar services to propel your
              business to the next level. Our curated services cater to startups
              and large enterprises alike, covering all technological needs.
            </h6>
          </div>
          <div className="home-about-service-section">
            <Row>
              {homeAboutList.map((item) => {
                return (
                  <Col sm="12" lg="3" md="6">
                    <Link to="/services">
                      <div className="home-about-item">
                        <div className={`habt-img ${item.keyword}`}>
                          <img src={item.imgURL} alt={item.title} />
                        </div>
                        <h4>{item.title}</h4>
                        <h6>{item.tagline}</h6>
                        <div className="habt-arrow-img">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="10.255"
                            viewBox="0 0 12 10.255"
                          >
                            <path
                              data-name="Path 11514"
                              d="m-2.322-6.546.322-.3-.322-.3-4.51-4.51-.3-.322-.617.617.322.3 3.758 3.785H-14v.859h10.336l-3.759 3.791-.322.3.617.617.3-.322z"
                              transform="translate(14 11.969)"
                              style={{ fill: "#fff" }}
                            />
                          </svg>
                        </div>
                      </div>
                    </Link>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </section>
      {/* Project CTA */}
      <section className="project-cta">
        <div className="project-stat-content">
          <div className="container project-stat-text-content">
            <img src={project_cta} alt="Project CTA" />
            <div className="proj-stat-text">
              <h5>Ready to kickstart your </h5>
              <h2>Dream business?</h2>
              <Link to="/contact">
                <button className="common-btn white-btn">
                  Get Started
                  <div className="common-btn-img">
                    <img src={right_arrow} alt="Right" />
                  </div>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Projects Section */}
      <section className="projects-section">
        <div className="container">
          <h1>
            We help clients reduce recurring costs and provide seamless
            experiences for their customers. By addressing real-world problems
            with optimised solutions, we generate better leads and drive
            business growth.
          </h1>
          <div className="olw-wrap">
            <div className="olw-left">
              <h4>Our Latest Work</h4>
              <h6>
                Explore our exciting few projects that showcase our digital
                transformation expertise.
              </h6>
            </div>
            <Link to="/portfolio">
              <button className="common-btn">
                View All Projects
                <div className="common-btn-img">
                  <img src={right_arrow_white} alt="Right" />
                </div>
              </button>
            </Link>
          </div>
          <section className="project-area-wrap">
            <div className="project-items-wrap">
              <div className="project-item">
                <Row>
                  <Col sm="12" lg="5" md="5" className="project-item-col">
                    <div className={`project-item-content`}>
                      <div
                        className="project-intersection-line"
                        ref={sectionRefEdr}
                      ></div>
                      <span>01</span>
                      <h4>Escape Debt Review</h4>
                      <h6 style={{ "--color": "#f7941e" }}>
                        Quick Debt Relief
                      </h6>
                      <p>
                        Escape Debt Review helps customers eliminate debt review
                        struggles through an easy online process.
                      </p>
                    </div>
                    <div className={`project-item-content`}>
                      <div
                        className="project-intersection-line"
                        ref={sectionRefCam}
                      ></div>
                      <span>02</span>
                      <h4>Trafford Care Consultancy</h4>
                      <h6 style={{ "--color": "#005eb8" }}>
                        Streamlined Healthcare Solutions
                      </h6>
                      <p>
                        Trafford Care Consultancy (TCC) specialises in aiding
                        healthcare agencies to establish and grow in the UK.
                      </p>
                    </div>
                    <div className={`project-item-content`}>
                      <div
                        className="project-intersection-line"
                        ref={sectionRefKing}
                      ></div>
                      <span>03</span>
                      <h4>Cape Granite Works</h4>
                      <h6 style={{ "--color": "#212121" }}>
                        Premium Granite Worktops
                      </h6>
                      <p>
                        Cape Granite Works offers top-quality granite worktops
                        in Cape Town, showcasing a wide range of products and
                        services.
                      </p>
                    </div>
                    <div className={`project-item-content`}>
                      <div
                        className="project-intersection-line"
                        ref={sectionRefSouth}
                      ></div>
                      <span>04</span>
                      <h4>Southern Packaging</h4>
                      <h6 style={{ "--color": "#eecc35" }}>
                        Showcase Your Products
                      </h6>
                      <p>
                        Southern Packaging presents its diverse product range
                        clearly and attractively.
                      </p>
                    </div>
                    <div className={`project-item-content`}>
                      <div
                        className="project-intersection-line"
                        ref={sectionRefRefurn}
                      ></div>
                      <span>05</span>
                      <h4>Refurnish</h4>
                      <h6 style={{ "--color": "#019345" }}>
                        Refurbished Product Sales
                      </h6>
                      <p>
                        A new platform will facilitate the sale of refurbished
                        products online, improving customer access and sales
                        efficiency.
                      </p>
                      <div
                        className={`animated-btn-wrap ${
                          elementIsRefurn ? "button-animated-in" : ""
                        }`}
                      >
                        <Link to="/portfolio">
                          <button className="common-btn">
                            View All Projects
                            <div className="common-btn-img">
                              <img src={right_arrow_white} alt="Right" />
                            </div>
                          </button>
                        </Link>
                      </div>
                    </div>
                    {/* {projectsList.map((item, i) => {
                      return (
                        <div
                          className={`project-item-content item-${item.id}`}
                          key={i}
                          ref={sectionRef}
                        >
                          <span>0{item.id}</span>
                          <h4>{item.title}</h4>
                          <h6 style={{ "--color": item.tagColor }}>
                            {item.tagline}
                          </h6>
                          <p>{item.desc}</p>
                        </div>
                      );
                    })} */}
                  </Col>
                  <Col sm="12" lg="7" md="7">
                    <div className="project-item-image">
                      <img src={laptopImage} alt="Projects" />
                      <div
                        className={`project-image-in ${
                          elementIsEdr
                            ? "edr-in"
                            : elementIsCam
                            ? "cam-in"
                            : elementIsKing
                            ? "king-in"
                            : elementIsSouth
                            ? "south-in"
                            : elementIsRefurn
                            ? "refurn-in"
                            : "refurn-in-last"
                        }`}
                      ></div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </section>
        </div>
      </section>
      {/* Newsletter */}
      <section className="newsletter-section">
        <div className="container">
          <Row>
            <Col sm="12" lg="7" md="12" className="d-flex align-items-center">
              <div className="news-content-wrap">
                <div className="news-text-wrap">
                  <h4>Why you should be excited?</h4>
                  <h5>Stay tuned for our next big innovation</h5>
                  <h6>Why You Should Be Excited?</h6>
                  <p>
                    At Devship, we're constantly pushing the boundaries of
                    what's possible. Our upcoming release is set to
                    revolutionise the way you operate, offering a new level of
                    efficiency and innovation.
                  </p>
                </div>
                <div className="news-subscribe-wrap">
                  <h6>Stay Informed</h6>
                  <p>Sign up now to be the first to hear all the details</p>
                  <form
                    className="nwsub-input-wrap"
                    onSubmit={handleSubSubmit}
                    ref={subForm}
                  >
                    <input
                      type="text"
                      className="nwsub-input"
                      placeholder="Email"
                      name="email"
                      id="email"
                      value={email}
                      onChange={handleChange}
                    />
                    <button
                      className="common-btn"
                      type="submit"
                      disabled={loading ? true : false}
                    >
                      {loading ? "Sending your email..." : "Stay in the loop"}

                      <div className="common-btn-img">
                        <img src={right_arrow_white} alt="Right" />
                      </div>
                    </button>
                  </form>
                </div>
              </div>
            </Col>
            <Col sm="12" lg="5" md="12">
              <div className="news-image">
                <img src={newsletter_img} alt="Newsletter" />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* Our Track Record */}
      <section className="our-stats">
        <div className="ourstats-container container">
          <div>
            <h2>
              Our Track <span>Record</span>
            </h2>
            <p>
              We have partnered with our clients to build their dreams, adding
              colour to their visions and exceeding expectations.
            </p>
          </div>
          <div className="stats-box-wrap">
            <Row>
              <Col sm="12" lg="6" md="12">
                <div className="stat-box proj-done">
                  <div className="stat-box-text">
                    <img
                      loading="lazy"
                      src={projectDoneTick}
                      alt="Projects Done"
                    />
                    <h4>45+</h4>
                    <h5>Projects Completed</h5>
                    <h6>Innovative and successful projects delivered</h6>
                  </div>
                  <img
                    src={projectDone}
                    alt="Projects Done"
                    className="stat-big-img projects-done"
                  />
                  <img
                    src={projectDoneSelected}
                    alt="Projects Done"
                    className="stat-big-img projects-done selected"
                  />
                </div>
              </Col>
              <Col sm="12" lg="6" md="12">
                <Row>
                  <Col sm="12" lg="6" md="6">
                    <div className="stat-box">
                      <div className="stat-box-text">
                        <img
                          loading="lazy"
                          src={hoursCoding}
                          alt="Hours of Coding"
                        />
                        <h4>8000+</h4>
                        <h5>Hours of Coding</h5>
                        <h6>Dedicated coding hours to bring ideas to life</h6>
                      </div>
                    </div>
                  </Col>
                  <Col sm="12" lg="6" md="6">
                    <div className="stat-box">
                      <div className="stat-box-text">
                        <img
                          loading="lazy"
                          src={graphicsDesigned}
                          alt="Graphics Designed"
                        />
                        <h4>3000+</h4>
                        <h5>Graphics Designed</h5>
                        <h6>Creative graphics crafted to perfection</h6>
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="stat-box hap-cl">
                  <div className="stat-box-text">
                    <img
                      loading="lazy"
                      src={happyClientsHeart}
                      alt="Happy Clients"
                    />
                    <h4>30+</h4>
                    <h5>Happy Clients</h5>
                    <h6>Satisfied clients who trust our expertise</h6>
                  </div>
                  <img
                    src={qualityAssurance}
                    alt="Happy Clients"
                    className="stat-big-img happy-clients"
                  />
                  <img
                    src={qualityAssuranceSelected}
                    alt="Happy Clients"
                    className="stat-big-img happy-clients selected"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      {/* Why Choose Us */}
      <section className="why-choose-container">
        <div className="container">
          <Row>
            <Col sm="12" lg="5" md="5" className="wcu-left-text">
              <div className="wc-content">
                <h4>Why Choose Us?</h4>
                <h5>Transforming Dreams into Digital Reality</h5>
                <p>
                  We provide the most accurate solutions for real-world
                  problems, transforming them into exceptional digital products.
                </p>
                <p>
                  Choosing the right software development service is crucial for
                  bringing your dream projects to life. We ensure the use of
                  optimal techniques, strategies, secure and efficient code, and
                  the right implementation.
                </p>
                <p>
                  Our commitment is to put your vision first, constructing it
                  with immaculate practices, advanced tools, and industry
                  standards. We believe quality and consistency are the keys to
                  success.
                </p>
              </div>
            </Col>
            <Col sm="12" lg="7" md="7" className="wcu-right-col">
              <div className="wcu-right-img-wrap">
                <img src={whychooseus} alt="Why Choose Us" />
              </div>
            </Col>
          </Row>
          <div className="why-choose-us-points">
            <div className="wcup-item-wrap">
              <div className="wcup-item first">
                <div className="wcup-text">
                  <span>01</span>
                  <h5>Client-Centric Approach</h5>
                  <p>
                    We focus on a client-centric approach, adapting to your
                    needs and delivering even on challenging projects with
                    stringent timelines.
                  </p>
                </div>
                <div className="wcup-img">
                  <img
                    src={client_centric}
                    className="wcu-icon"
                    alt="Client Centric"
                  />
                </div>
              </div>
              <div className="wcup-item invert second">
                <div className="wcup-text">
                  <span>02</span>
                  <h5>Productive Small Teams</h5>
                  <p>
                    Our small, efficient teams work synergistically to deliver
                    significant results quickly and effectively, ensuring your
                    projects are completed better and faster.
                  </p>
                </div>
                <div className="wcup-img">
                  <img
                    src={prod_small_team}
                    className="wcu-icon"
                    alt="Productive Small Teams"
                  />
                </div>
              </div>
            </div>
            <div className="wcup-item-wrap mb-3">
              <div className="wcup-item icon-top third">
                <div className="wcup-text">
                  <span>03</span>
                  <h5>Expertise &amp; Skill</h5>
                  <p>
                    We employ highly skilled professionals in business and
                    development to bring your projects to life with precision
                    and efficiency.
                  </p>
                </div>
                <div className="wcup-img">
                  <img
                    src={expert_skill}
                    className="wcu-icon"
                    alt="Expertise & Skill"
                    style={{ right: "0.3rem" }}
                  />
                </div>
              </div>
              <div className="wcup-item invert icon-top fourth">
                <div className="wcup-text">
                  <span>04</span>
                  <h5>Quick Adaptation</h5>
                  <p>
                    From day one, we conduct thorough research and planning,
                    gathering feedback to enhance product efficiency and meet
                    your evolving needs.
                  </p>
                </div>
                <div className="wcup-img">
                  <img
                    src={quick_adapt}
                    className="wcu-icon"
                    alt="Quick Adaptation"
                    style={{ left: "-0.6rem" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      <Assistance
        title="Need Assistance?"
        desc="Let's meet over a coffee to discuss your next big dream project. We're here to help you every step of the way."
        button="Start a Project"
        link="/contact"
        imgURL={AssistanceImg}
      />
    </>
  );
}

export default Home;
