import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import "./Portfolio.css";
import OwlCarousel from "react-owl-carousel";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import Assistance from "../Common/Assistance";

// Image Imports

import AssistanceImg from "../../../images/serv_assistance_img.svg";
import portfolio_bg from "../../../images/portfolio.webm";
import biz_dev_manager from "../../../images/biz_dev_manager.svg";
import port_email from "../../../images/icons/port_email.svg";
import port_call from "../../../images/icons/port_call.svg";
import Edr from "../../../images/projects/Edr.png";
import Cam from "../../../images/projects/Cam.png";
import Kg from "../../../images/projects/Kg.png";
import Sp from "../../../images/projects/Sp.png";
import Dr from "../../../images/projects/Dr.png";

// Logos
import Carewise from "../../../images/clients/Carewise.png";
import CapeGranite from "../../../images/clients/Cape_Granite.svg";
import FamiliCloud from "../../../images/clients/FamiliCloud.svg";
import UrbanDwell from "../../../images/clients/UrbanDwell.svg";
import RegalEstates from "../../../images/clients/RegalEstates.svg";
import Highstreet from "../../../images/clients/Highstreet.svg";
import PeakSports from "../../../images/clients/PeakSports.svg";
import Refurnish from "../../../images/clients/Refurnish.png";
import Escape from "../../../images/clients/Escape.png";
import Brightpath from "../../../images/clients/Brightpath.png";
import SouthPack from "../../../images/clients/SouthPack.png";
import Camden from "../../../images/clients/Camden.png";
import ElderCare from "../../../images/clients/Elder_care.png";
import Tcc from "../../../images/clients/Tcc.png";

// End of Image Imports

const clientLogo = [
  {
    id: 1,
    imgURL: Carewise,
  },
  {
    id: 2,
    imgURL: CapeGranite,
  },
  {
    id: 3,
    imgURL: FamiliCloud,
  },
  {
    id: 4,
    imgURL: UrbanDwell,
  },
  {
    id: 5,
    imgURL: RegalEstates,
  },
  {
    id: 6,
    imgURL: Highstreet,
  },
  {
    id: 7,
    imgURL: PeakSports,
  },
  {
    id: 8,
    imgURL: Refurnish,
  },
  {
    id: 9,
    imgURL: Escape,
  },
  {
    id: 10,
    imgURL: Brightpath,
  },
  {
    id: 11,
    imgURL: SouthPack,
  },
  {
    id: 12,
    imgURL: Camden,
  },
  {
    id: 13,
    imgURL: ElderCare,
  },
  {
    id: 14,
    imgURL: Tcc,
  },
];

const options = {
  loop: true,
  margin: 10,
  responsiveClass: true,
  responsive: {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    800: {
      items: 4,
    },
    1000: {
      items: 5,
    },
  },
  nav: false,
  autoplay: true,
  autoplayTimeout: 2000,
  autoplayHoverPause: true,
  dots: false,
};

function Portfolio() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title =
      "Portfolio • Devship";
    const watchScroll = () => {
      window.addEventListener("scroll", handleScroll);
    };
    watchScroll();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [changeScroll, setChangeScroll] = useState(false);

  const handleScroll = () => {
    const scrollPos = window.scrollY;
    if (scrollPos > 20) {
      setChangeScroll(true);
    } else {
      setChangeScroll(false);
    }
  };

  return (
    <>
      <section className="company-portfolio-wrap">
        <div className="comp-portfolio-header">
          <div className="comp-video-bg">
            <video id="portVideo" loop muted autoPlay>
              <source src={portfolio_bg} />
            </video>
          </div>
          <div className="comp-port-content">
            <h2>Our Portfolio</h2>
            <h3>Showcasing Our Journey of Excellence</h3>
            <h6>
              Over the years, we have had the privilege of working on a diverse
              range of projects, each one a testament to our commitment to
              quality and innovation. From cutting-edge applications to
              comprehensive management systems, our portfolio reflects the
              expertise and dedication we bring to every challenge. Explore our
              completed projects and see how we've helped businesses and
              individuals achieve their goals through technology.
            </h6>
          </div>
          <div className="next-section-line-wrap white">
            <div
              className={`nsl-circle white ${changeScroll ? "scroll-in" : ""}`}
            >
              <div className="nsl-circle-small"></div>
            </div>
            <div className="nsl-line"></div>
          </div>
        </div>
        <div className="projects-total-wrap">
          {/* Escape Debt */}
          <div className="project-items-wrap">
            <div className="container">
              <div className="project-item">
                <Row>
                  <Col sm="12" lg="5" md="12">
                    <div className="project-item-content">
                      <h4>Escape Debt Review</h4>
                      <span className="pr-tag orange">Quick Debt Relief</span>
                      <p>
                        Escape Debt Review helps customers eliminate debt review
                        struggles through an easy online process.
                      </p>
                      <h6>Connect with Experts</h6>
                      <p>
                        Our platform links users with experienced attorneys for
                        debt review flag removal.
                      </p>
                      <p>
                        We developed this end-to-end service to assist South
                        African customers in achieving financial freedom. Live
                        and actively helping hundreds, it ensures a seamless
                        experience in removing Debt Review flags from credit
                        profiles.
                      </p>
                    </div>
                  </Col>
                  <Col sm="12" lg="7" md="12">
                    <div className="project-item-image">
                      <img src={Edr} alt="Escape Debt" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {/* TCC */}
          <div className="project-items-wrap cam">
            <div className="container">
              <div className="project-item center">
                <h4>Trafford Care Consultancy</h4>
                <span className="pr-tag blue">
                  Streamlined Healthcare Solutions
                </span>
                <p>
                  Trafford Care Consultancy (TCC) specialises in aiding
                  healthcare agencies to establish and grow in the UK.
                </p>
                <div className="project-item-image">
                  <img src={Cam} alt="Escape Debt" />
                </div>
                <h6>All-in-One Platform</h6>
                <p>
                  We developed a comprehensive platform for TCC to organise
                  their operations for clients and staff. TCC, a leader in the
                  healthcare industry, has utilised our end-to-end service
                  platform, benefiting over 1000 clients. The application
                  supports efficient management and operational processes,
                  ensuring seamless service delivery.
                </p>
              </div>
            </div>
          </div>
          {/* CGW */}
          <div className="project-items-wrap">
            <div className="container">
              <div className="project-item">
                <Row>
                  <Col sm="12" lg="5" md="12">
                    <div className="project-item-content">
                      <h4>Cape Granite Works</h4>
                      <span className="pr-tag black">
                        Premium Granite Worktops
                      </span>
                      <p>
                        Cape Granite Works offers top-quality granite worktops
                        in Cape Town, showcasing a wide range of products and
                        services.
                      </p>
                      <h6>User-Friendly Online Platform</h6>
                      <p>
                        We developed a customer-centric website for Cape Granite
                        Works, allowing easy product viewing and quote requests.
                      </p>
                      <p>
                        This new platform enhances customer experience by
                        providing seamless navigation through the product and
                        service offerings. The live website has successfully
                        facilitated numerous customer inquiries and quotes,
                        supporting Cape Granite Works in delivering exceptional
                        granite worktops.
                      </p>
                    </div>
                  </Col>
                  <Col sm="12" lg="7" md="12">
                    <div className="project-item-image">
                      <img src={Kg} alt="Cape Granite Works" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {/* DR */}
          <div className="project-items-wrap refurnish">
            <div className="container">
              <div className="project-item">
                <Row className="proj-refurnish">
                  <Col sm="12" lg="7" md="12">
                    <div className="project-item-image">
                      <img src={Dr} alt="Refurnish" />
                    </div>
                  </Col>
                  <Col sm="12" lg="5" md="12">
                    <div className="project-item-content">
                      <h4>Refurnish</h4>
                      <span className="pr-tag green">
                        Streamlined Warehouse Operations
                      </span>
                      <p>
                        We are developing a new website for Doncaster Refurnish
                        to automate and digitalise their warehouse operations.
                      </p>
                      <h6>Refurbished Product Sales</h6>
                      <p>
                        A new platform will facilitate the sale of refurbished
                        products online, improving customer access and sales
                        efficiency.
                      </p>
                      <h6>POS Application for Shop Owners</h6>
                      <p>
                        Additionally, we are creating a POS application to
                        enhance in-store sales and management for shop owners.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          {/* SP */}
          <div className="project-items-wrap">
            <div className="container">
              <div className="project-item">
                <Row>
                  <Col sm="12" lg="5" md="12">
                    <div className="project-item-content">
                      <h4>Southern Packaging</h4>
                      <span className="pr-tag yellow">
                        Showcase Your Products
                      </span>
                      <p>
                        Southern Packaging presents its diverse product range
                        clearly and attractively.
                      </p>
                      <h6>Enhanced User Experience</h6>
                      <p>
                        We developed a website for Southern Packaging that
                        categorises products to enhance client attraction and
                        user experience.
                      </p>
                      <h6>Easy Enquiries</h6>
                      <p>
                        Customers can easily enquire about products and services
                        using the enquiry form, ensuring quick and efficient
                        communication.
                      </p>
                    </div>
                  </Col>
                  <Col sm="12" lg="7" md="12">
                    <div className="project-item-image">
                      <img src={Sp} alt="Southern Packaging" />
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>
        {/* Interesting in starting a project */}
        {/* <div className="interested-project-cta container">
          <h2>Interested in Starting a Project?</h2>
          <h5>
            If you’re inspired by our work and have a project in mind, reach out
            to our UK-based Business Development Manager
          </h5>
          <div className="ip-prof-wrap">
            <Row>
              <Col sm="12" lg="5" md="12">
                <img src={biz_dev_manager} alt="Business" />
              </Col>
              <Col sm="12" lg="7" md="12">
                <h4>Oliver Henry</h4>
                <h6>Business Development Manager (UK)</h6>
                <ul>
                  <li>
                    <img src={port_email} alt="Email" />
                    oliver.henry@tekniik.co.uk (or) business@devship.in
                  </li>
                  <li>
                    <img src={port_call} alt="Call" />
                    +44 161 850 1234
                  </li>
                </ul>
                <p className="port_foot_note">
                  Oliver, based in Manchester, UK, is here to discuss your
                  business needs and help you explore how we can create a
                  tailored solution for your company. With his local expertise
                  and understanding of the market, Oliver is ready to assist you
                  with all your project inquiries.
                </p>
              </Col>
            </Row>
          </div>
        </div> */}
        <div className="clients-partner-wrap">
          <div className="container">
            <h4>Our Clients</h4>
            <h2>Proud to Partner With</h2>
            <div className="clients-partner-logo-wrap">
              <OwlCarousel className="owl-theme" {...options}>
                {clientLogo.map((item) => {
                  return (
                    <div className="item">
                      <div className="clients-item">
                        <img src={item.imgURL} alt="Devship" />
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      <Assistance
        title="Ready to Collaborate?"
        desc="Let's meet over a coffee to discuss your next big dream project. We're here to help you every step of the way."
        button="Reach Us"
        link="/contact"
        imgURL={AssistanceImg}
        background={false}
      />
    </>
  );
}

export default Portfolio;
